<template>
  <ion-page>
    <Header name="Suche" />
    <ion-content 
      :fullscreen="true"
      :scroll-events="true"
      @ionScroll="onScroll($event)"
    >
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <ion-grid>

          <ion-row>
              <ion-col>
                  <p v-if="showNoEntries">Ihre Suche nach <b>{{ query }}</b> ergab leider keine Treffer.</p>
                  <p v-else>Ihre Suche nach <b>{{ query }}</b> ergab <b>{{ numResults }}</b> Treffer:</p>
              </ion-col>
          </ion-row>

          <div v-if="hasResults">
            <ion-row 
              v-for="(entry, index) in newsResults" 
              :key="index"
              @click="openNewsDetailPage(entry.id)"
            >
              <ion-col>
                <div class="card news-entry">
                  <div class="content">
                    <div v-if="hasImage(entry)" class="image">
                      <img :src="getImageUrl(entry.imageUrl)" width="120" height="90" />
                    </div>
                    <div class="text">
                      <h3 class="font-face--merrisans light">{{ entry.title }}</h3>
                    </div>
                  </div>
                  <div class="meta">
                    <time class="font-face--merrisans light">{{ getFormattedDate(entry.datetime) }}</time>
                    <span v-if="entry.categories.length" class="font-face--merrisans light">
                      &nbsp; | &nbsp; {{ getCategoriesString(entry.categories) }}
                    </span>
                  </div>
                </div>
              </ion-col>
            </ion-row>

            <ion-row 
              v-if="showLoadMore" 
              class="load-more"
              :class="{'invisible': isLoading}"
            >
              <ion-col>
                <a href="#" class="btn btn-next btn-secondary" role="button" @click.prevent="loadMore(null, true)">
                  <span>Mehr laden</span>
                </a>
              </ion-col>
            </ion-row>
          </div>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent 
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'SearchResults',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent
  },
  data () {
    return {
      start: 1,
      isLoading: false,
      totalEntries: 0,
      entries: [],
      lastScrollLoad: 0
    }
  },
  computed: {
    showLoadMore (): boolean {
      return (this as any).totalEntries > (this as any).entries.length
    },
    showNoEntries (): boolean {
      return !(this as any).isLoading && !(this as any).entries.length 
    },
    hasResults (): boolean {
      return !!(this as any).entries.length
    },
    numResults (): number {
      return (this as any).totalEntries
    },
    query (): string {
      return (this as any).$route.params.query
    },
    newsResults (): any {
      return (this as any).entries
      //return (this as any).entries.filter((searchHit: any) => searchHit.type === 'news')
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    onScroll (event: any) {
      const vm = (this as any);
      let shouldLoad = vm.shouldLoadMoreBasedOnScrollPosition();
      if (shouldLoad && !vm.isLoading && 
        event.detail.scrollTop > (vm.lastScrollLoad + (document.body.clientHeight / 2))
      ) {
          vm.lastScrollLoad = event.detail.scrollTop;
          console.log('loading new content due to scroll position');
          vm.loadMore(null, true);
        }
    },
    reset () {
      (this as any).start = 1;
      (this as any).isLoading = false;
      (this as any).totalEntries = 0;
      (this as any).entries = []; 
      (this as any).lastScrollLoad = 0;
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    async loadMore (event: any, loadOnClick: boolean) {
      console.log('start load');
      const vm = (this as any);

      if (loadOnClick) {
        console.log('loading new content due to user interaction');
      } else {
        vm.setLoading(true);
      }

      try {
        const data = await api.search(vm.query, vm.start);
        console.log(data);

        if (data.data.entries.length) {
          for (let i in data.data.entries) {
            vm.entries.push(data.data.entries[i]);        
          }
          vm.totalEntries = data.data.totalEntries;
          vm.start += 1;
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      console.log(vm.entries);
      vm.setLoading(false);

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadMore(event);
      }
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    openNewsDetailPage (id: number) {
      (this as any).$router.push({ path: `/news/${id}` });
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave search');
    (this as any).reset();
  },
  ionViewDidEnter () {
    console.log('[ionView] entered search');
    (this as any).doRefresh();
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
</script>

<style scoped lang="less">
.load-more {
  margin-top: 15px;
}

.blog-header {
  height: 130px;
  width: 100%;
  margin-top: -20px;
  padding: 20px 30px;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    max-width: 250px;
  }
}

.invisible {
  opacity: 0;
}

.hidden {
  display: none;
}

.blog-entry {
  h3 {
    font-size: 18px !important;
    line-height: 28px !important;
    color: var(--text-color-grey);
    margin-bottom: 5px;
    margin-top: 3px !important;
  }

  time {
    font-size: 12px !important;
    line-height: 20px !important;
    color: var(--text-color-lightgrey);
    margin-bottom: 15px;
  }

  .meta {
    padding: 0 !important;
    margin: 0 15px !important;
  }
}

@media (prefers-color-scheme: dark) {
  .card {
    background: var(--ion-card-background);

    h3 {
      color: var(--background-lightblue);
    }

    p {
      color: #fff;
    }
  }
}
body.dark {
  .card {
    background: var(--ion-card-background);

    h3 {
      color: var(--background-lightblue);
    }

    p {
      color: #fff;
    }
  }
}
</style>